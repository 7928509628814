var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5b1ac0b05c51a653d970dd7a078eed672fabf885"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { ENV } from 'utils/env';
import { isSentryEnabled, SENTRY_ENVIRONMENT } from 'utils/sentry';

const SENTRY_CLIENT_SIDE_IGNORED_ERRORS = [
  // React 18 SSR hydration errors
  'Hydration failed because the initial UI does not match',
  'Text content does not match server-rendered HTML',
  'There was an error while hydrating',

  // Wallet rejections
  'user rejected transaction',

  // Wallet connect errors
  'Socket stalled when trying to connect to wss://relay.walletconnect.org',
  'WebSocket connection failed for host: wss://relay.walletconnect.org',

  // coinbase noise
  'websocket error 1006',

  // unhelpful, or unfixable errors
  'No error message',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Non-Error promise rejection captured with keys:',
  'Invariant: attempted to hard navigate to the same URL',
  "'defineProperty' on proxy: trap returned falsish for property 'request'",

  /**
   * Unactionable issue with Next.js internals
   * @see https://onfoundation.slack.com/archives/C017RD13WMN/p1679328333162709
   */
  "Cannot read properties of null (reading 'content')",
];

Sentry.init({
  dsn: ENV.SENTRY_DSN,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: isSentryEnabled(),
  environment: SENTRY_ENVIRONMENT,
  ignoreErrors: SENTRY_CLIENT_SIDE_IGNORED_ERRORS,
  tracesSampleRate: 1.0,

  beforeSend(event) {
    if (!event.tags) {
      event.tags = {};
    }
    event.tags['side'] = 'client';
    return event;
  },
  integrations: [],
});
